.signature-canvas {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
}

.heightIsSmallestDimension {
    height: 100vh;
    width: calc(100vh * 3); 
}

.widthIsSmallestDimension {
    width: 100vw;
    height: calc(100vw / 3);
}

.signature-hint {
    display: none;
}

.disabled {
    pointer-events: none;
}

@media all and (orientation: portrait) and (max-width: 600px) {
    .signature-hint {
        display: block;
        position: absolute;
    }

    .signature-buttons {
        visibility: hidden;
    }

    .signature-line {
        visibility: hidden;
    }

    .signature-canvas {
        pointer-events: none;
    }
}