.rdrDateRangePickerWrapper {
    font-family: "Roboto" !important;
}

.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  font-size: 14px;
  font-family: "Roboto" !important;
}

.rdrDateRangePickerWrapper .rdrDateRangeWrapper {
    font-size: 13px;
}
