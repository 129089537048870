.react-pdf__Page__svg {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
}

.react-pdf__Page__svg svg {
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Page__textContent {
    display: none !important;
}