body {
  font-family: Roboto;
  font-size: 14px;

  color: #3e3f42;
}

.mdc-snackbar {
  top: 0;
  bottom: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* when there's a modal open make sure its above the drawer */
.mdc-dialog-scroll-lock #rmwcPortal {
  position: relative;
  z-index: 1000; /* needs to be above the drawer */
}

.mdc-snackbar {
  z-index: 1001;
}

.mdc-menu-surface {
  z-index: 100;
}

.mdc-dialog {
  z-index: 101;
}

.rmwc-collapsible-list {
  border-bottom: 1px solid #eaedf3;
}

.rmwc-collapsible-list__children-inner {
  padding-bottom: 12px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  animation: spin 1s linear infinite;
}
